import React from "react";
import { Link } from "react-router-dom";
import { bankCategories, categories } from "../../data/staticData";
import MainSlider from "../Slider/MainSlider";

function ListComponent() {
  return (
    <div className="list_box">
      <div className="main_list">
        <ul className="list">
          {categories.slice(0, 8).map((item, i) => (
            <li className="item" key={i}>
              <img
                src={`${item.img}`}
                className="item_bg"
                alt={item.img}
                loading="lazy"
                decoding="async"
              />
              <Link to={`/calc/${item.id}`} className="link_box">
                <div className="category_box">
                  <span>부동산 계산기</span>
                </div>
                <div className="title_box">
                  <span>{item.name}</span>
                </div>
                <div className="sub_title_box">
                  <span>{item.description}</span>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="main_list">
        <ul className="list">
          <li className="item double_item">
            <MainSlider />
          </li>
          {bankCategories.map((item, i) => (
            <li className="item" key={i}>
              <img
                src={`${item.img}`}
                className="item_bg"
                alt={item.img}
                loading="lazy"
                decoding="async"
              />
              <Link to={`/rate/${item.id}`} className="link_box">
                <div className="category_box">
                  <span>상품 정리</span>
                </div>
                <div className="title_box">
                  <span>{item.name}</span>
                </div>
                <div className="sub_title_box">
                  <span>{item.description}</span>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ListComponent;
