import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import { slideItem } from "../../data/staticData";


function MainSlider() {
  const containerRef = useRef(null);
  const sliderRef = useRef(null);
  const transitionRef = useRef(null);
  const timerRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(1);
  const [translate, setTranslate] = useState(0);
  const [animate, setAnimate] = useState(true);
  const slideCount = slideItem.length;

  // Extended slides for infinite looping
  const extendedSlideItems = [
    slideItem[slideCount - 1],
    ...slideItem,
    slideItem[0],
  ];

  // Memoized nextSlide function
  const nextSlide = useCallback(() => {
    setCurrentIndex((prev) => {
      if (prev >= extendedSlideItems.length - 1) return prev;
      return prev + 1;
    });
    setAnimate(true);
  }, [extendedSlideItems.length]);

  // Update transitionRef with nextSlide
  useEffect(() => {
    transitionRef.current = nextSlide;
  }, [nextSlide]);

  // Initialize translate position
  useLayoutEffect(() => {
    if (containerRef.current) {
      const slideWidth = containerRef.current.offsetWidth;
      setTranslate(-slideWidth * currentIndex);
    }
  }, [currentIndex]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const slideWidth = containerRef.current.offsetWidth;
        setTranslate(-slideWidth * currentIndex);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [currentIndex]);

  // Start auto-slide timer with proper clearing
  const startTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(() => {
      transitionRef.current();
    }, 3000);
  }, []);

  // Reset auto-slide timer
  const resetTimer = useCallback(() => {
    startTimer();
  }, [startTimer]);

  // Initialize timer on mount
  useEffect(() => {
    startTimer();
    return () => clearInterval(timerRef.current);
  }, [startTimer]);

  // Handle transition end for infinite looping
  const handleTransitionEnd = () => {
    if (currentIndex === 0) {
      setAnimate(false);
      setCurrentIndex(slideCount);
      if (containerRef.current) {
        const slideWidth = containerRef.current.offsetWidth;
        setTranslate(-slideWidth * slideCount);
      }
    } else if (currentIndex === extendedSlideItems.length - 1) {
      setAnimate(false);
      setCurrentIndex(1);
      if (containerRef.current) {
        const slideWidth = containerRef.current.offsetWidth;
        setTranslate(-slideWidth);
      }
    }
  };

  // Update translate on index or animation change
  useEffect(() => {
    if (animate && containerRef.current) {
      const slideWidth = containerRef.current.offsetWidth;
      setTranslate(-slideWidth * currentIndex);
    }
  }, [currentIndex, animate]);

  // Handle pagination dot click
  const handleDotClick = (index) => {
    setCurrentIndex(index + 1);
    if (containerRef.current) {
      const slideWidth = containerRef.current.offsetWidth;
      setTranslate(-slideWidth * (index + 1));
    }
    setAnimate(true);
    resetTimer();
  };

  // Pause auto-slide on mouse enter
  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  // Resume auto-slide on mouse leave
  const handleMouseLeave = () => {
    resetTimer();
  };

  return (
    <div
      className="slider_container"
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`slider_wrapper ${animate ? "animate" : ""}`}
        ref={sliderRef}
        style={{
          transform: `translateX(${translate}px)`,
          width: `calc(${extendedSlideItems.length * 100}% - 1px)`,
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        {extendedSlideItems.map((data, index) => (
          <div
            key={index}
            className="slide"
            style={{ flex: `0 0 ${100 / extendedSlideItems.length}%` }}
          >
            <img
              className="slide_img"
              src={data.img}
              alt={`Slide ${index + 1} - ${data.company}`}
              loading="lazy"
              decoding="async"
              draggable="false"
            />
            <div className="slide_content">
              <span className="company">{data.company}</span>
              <span className="content">{data.content}</span>
              <a
                className="link_btn"
                href={data.url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
                aria-label={`Visit ${data.company} website`}
              >
                홈페이지 바로가기
              </a>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        {slideItem.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index + 1 ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
}

export default MainSlider;