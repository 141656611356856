import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header/Header";
import BugerMenu from "../../components/BurgerMenu/BurgerMenu";

function Main() {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  return (
    <>
      <div className="home">
        <div className="container">
          <Header
            isBurgerOpen={isBurgerOpen}
            setIsBurgerOpen={setIsBurgerOpen}
          />
          <div className="wrapper">
            <div className="content_box">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <BugerMenu
        isOpen={isBurgerOpen}
        closeMenu={() => setIsBurgerOpen(false)}
      />
    </>
  );
}

export default Main;
