import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/Main/Main";
import CalcPageComponent from "./components/CalcPageComponent/CalcPageComponent";
import BankPageComponent from "./components/BankPageComponent/BankPageComponent";
import ListComponent from "./components/ListComponent/ListComponent";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<ListComponent />} />
          <Route path="calc/:id" element={<CalcPageComponent />} />
          <Route path="rate/:id" element={<BankPageComponent />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
