import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { notReadyAlert } from "../../hooks/notReadyAlert";

function Header({ isBurgerOpen, setIsBurgerOpen }) {
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1280);
  const [isNavHovered, setIsNavHovered] = useState(false);

  // 추가: 어떤 서브메뉴 섹션에 호버되었는지를 관리하는 상태
  const [hoveredNavItem, setHoveredNavItem] = useState(null);

  const handleScroll = () => {
    if (isNavHovered) return;
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
    setLastScrollY(currentScrollY);
  };

  const handleResize = () => {
    if (window.innerWidth <= 1280) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      setIsBurgerOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [lastScrollY, setIsBurgerOpen, isNavHovered]);

  useEffect(() => {
    if (isNavHovered) {
      setShowHeader(true);
    }
  }, [isNavHovered]);

  const toggleBurger = () => {
    setIsBurgerOpen(!isBurgerOpen);
  };

  const closeBurger = () => {
    setIsBurgerOpen(false);
    setIsNavHovered(false);
    setHoveredNavItem(null);
  };

  useEffect(() => {
    if (isBurgerOpen || isNavHovered) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isBurgerOpen, isNavHovered]);

  // 메인 메뉴 항목 이름과 실제 표시될 이름 매칭(상단 네비게이션과 서브메뉴 섹션 연결)
  const mainNavItems = [
    { name: "부동산계산기", path: "/" },
    { name: "프라임지분거래소", path: "#", disabled: true },
    {
      name: "셀프투자유형분석",
      path: "https://survey.returnplus.kr/",
      external: true,
    },
    { name: "투자・자산 커뮤니티", path: "#", disabled: true },
  ];

  // hoveredNavItem과 비교해서 스타일 부여
  const getNavLinkStyle = (itemName) => {
    if (!isNavHovered || !hoveredNavItem) return {};
    return {
      opacity: hoveredNavItem === itemName ? 1 : 0.3,
    };
  };

  return (
    <div className="header">
      <div
        className={`header_container ${showHeader ? "" : "hidden"}${
          isNavHovered ? " is_nav_hovered" : ""
        }`}
      >
        <div className="header_wrapper">
          <div className="header_home_btn_box">
            <Link to="/" className="header_home_btn" onClick={closeBurger}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/logo.png`}
                alt="Logo"
              />
            </Link>
          </div>

          {isMobile ? (
            <div className="burger_menu">
              <button
                className={`burger_icon ${isBurgerOpen ? "open" : ""}`}
                onClick={toggleBurger}
                aria-label="Toggle menu"
                aria-expanded={isBurgerOpen}
                aria-controls="side-menu"
              >
                <span className="burger_line"></span>
                <span className="burger_line"></span>
                <span className="burger_line"></span>
              </button>
            </div>
          ) : (
            <div className="header_nav_area">
              <div className="header_nav_wrapper">
                <div
                  className="header_nav_box"
                  onMouseEnter={() => setIsNavHovered(true)}
                  onMouseLeave={() => {
                    setIsNavHovered(false);
                    setHoveredNavItem(null);
                  }}
                >
                  {mainNavItems.map((item, index) => {
                    if (item.external) {
                      return (
                        <a
                          key={index}
                          href={item.path}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={getNavLinkStyle(item.name)}
                          onClick={closeBurger} // Ensure external links also close the nav
                        >
                          {item.name}
                        </a>
                      );
                    } else if (item.disabled) {
                      return (
                        <Link
                          key={index}
                          onClick={notReadyAlert}
                          style={getNavLinkStyle(item.name)}
                        >
                          {item.name}
                        </Link>
                      );
                    }
                    return (
                      <Link
                        key={index}
                        to={item.path}
                        onClick={closeBurger}
                        style={getNavLinkStyle(item.name)}
                      >
                        {item.name}
                      </Link>
                    );
                  })}
                </div>
              </div>

              <div
                className={`sub_menu_contents_box  ${
                  isNavHovered ? "sub_menu_contents_box_open" : ""
                }`}
                onMouseEnter={() => setIsNavHovered(true)}
                onMouseLeave={() => {
                  setIsNavHovered(false);
                  setHoveredNavItem(null);
                }}
              >
                <div className="sub_menu_contents">
                  <Link to="/"
                    className="nav_section"
                    onMouseEnter={() => setHoveredNavItem("부동산계산기")}
                    onMouseLeave={() => setHoveredNavItem(null)}
                  >
                    <ul className="sub_menu_list">
                      <li>
                        <span>세금 계산</span>
                        <ul>
                          <li>
                            <Link to="/calc/Brokerage" onClick={closeBurger}>
                              중개보수
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/calc/CapitalGainsTax"
                              onClick={closeBurger}
                            >
                              양도세
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={notReadyAlert}
                            >
                              법인 양도세
                            </Link>
                          </li>
                          <li>
                            <Link to="/calc/HoldingTax" onClick={closeBurger}>
                              보유세
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/calc/AcquisitionTax"
                              onClick={closeBurger}
                            >
                              취득세
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>임대 투자</span>
                        <ul>
                          <li>
                            <Link
                              onClick={notReadyAlert}
                            >
                              임대수익률
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={notReadyAlert}
                            >
                              적정매수가
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={notReadyAlert}
                            >
                              간주임대료
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>금리 정보</span>
                        <ul>
                          <li>
                            <Link to="/rate/DepositRate" onClick={closeBurger}>
                              예금금리
                            </Link>
                          </li>
                          <li>
                            <Link to="/rate/LoanRate" onClick={closeBurger}>
                              대출금리
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`header_sub_menu ${
          isNavHovered ? "header_sub_menu_open" : ""
        }`}
      ></div>
    </div>
  );
}

export default Header;
